<template>
  <q-drawer
    show-if-above
    v-model="leftDrawerOpen"
    side="left"
    bordered
    class="app-sidebar-drawer__content"
    behavior="desktop"
  >
    <q-list class="app-menu">
      <q-expansion-item
        expand-separator
        icon="insights"
        label="Reporting"
        default-opened
      >
        <q-item
          class="--inset-level-1"
          to="/reporting/network-performance"
          exact
        >
          <q-item-section>
            Network Performance
          </q-item-section>
        </q-item>

        <q-item class="--inset-level-1" to="/reporting/lead-delivery" exact v-if="!drewRestrictedAccess">
          <q-item-section>
            Lead Delivery
          </q-item-section>
        </q-item>
      </q-expansion-item>

      <q-expansion-item expand-separator icon="tune" label="Management">
        <q-expansion-item
          :header-inset-level="0.5"
          dense-toggle
          switch-toggle-side
          expand-separator
          label="Network"
          v-if="!drewRestrictedAccess"
        >
          <q-item
            class="--inset-level-2"
            to="/manage/network/businesses"
            exact
          >
            <q-item-section>
              Businesses
            </q-item-section>
          </q-item>
          <q-item
            class="--inset-level-2"
            to="/manage/network/advertiser/accounts"
            exact
          >
            <q-item-section>
              Advertiser Accounts
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          :header-inset-level="0.5"
          dense-toggle
          switch-toggle-side
          expand-separator
          label="Offers"
        >
          <q-item class="--inset-level-2" to="/manage/offers" exact>
            <q-item-section>
              Overview
            </q-item-section>
          </q-item>
          <q-item class="--inset-level-2" to="/manage/offers/split-tests" exact>
            <q-item-section>
              Split Tests
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          :header-inset-level="0.5"
          dense-toggle
          switch-toggle-side
          expand-separator
          label="Keywords"
          v-if="!drewRestrictedAccess"
        >
          <q-item
            class="--inset-level-2"
            to="/manage/keywords/exclusions"
            exact
          >
            <q-item-section>
              Exclusions
            </q-item-section>
          </q-item>
          <q-item class="--inset-level-2" to="/manage/keywords/featured" exact>
            <q-item-section>
              Featured
            </q-item-section>
          </q-item>
          <q-item class="--inset-level-2" to="/manage/keywords/modifiers" exact>
            <q-item-section>
              Modifiers
            </q-item-section>
          </q-item>
        </q-expansion-item>
      </q-expansion-item>

      <!--<q-expansion-item expand-separator icon="ad_units" label="Ads">
        <q-expansion-item
          :header-inset-level="0.5"
          dense-toggle
          switch-toggle-side
          expand-separator
          label="Inventory Library"
        >
          <q-item class="--inset-level-2" to="/ads/inventory-library" exact>
            <q-item-section>
              Overview
            </q-item-section>
          </q-item>
          <q-item
            class="--inset-level-2"
            to="/ads/inventory-library/selector-tool"
            exact
          >
            <q-item-section>
              Selector Tool
            </q-item-section>
          </q-item>
        </q-expansion-item>
      </q-expansion-item>-->

      <q-expansion-item expand-separator icon="grid_view" label="Inventory" v-if="!drewRestrictedAccess">
        <q-item class="--inset-level-1" to="/inventory" exact>
          <q-item-section>
            Overview
          </q-item-section>
        </q-item>
        <q-item class="--inset-level-1" to="/inventory/sources">
          <q-item-section>
            Sources
          </q-item-section>
        </q-item>
        <q-item class="--inset-level-1" to="/inventory/pipeline">
          <q-item-section>
            Pipeline
          </q-item-section>
        </q-item>

        <!--      <q-expansion-item-->
        <!--          expand-separator-->
        <!--          icon="insights"-->
        <!--          label="Crawler"-->
        <!--      >-->
        <!--        <q-item-->
        <!--            class="&#45;&#45;inset-level-1"-->
        <!--            to="/crawler"-->
        <!--            exact-->
        <!--        >-->
        <!--          <q-item-section>-->
        <!--            Editor-->
        <!--          </q-item-section>-->
        <!--        </q-item>-->

        <!--        <q-item class="&#45;&#45;inset-level-1" to="/crawler/queue" exact>-->
        <!--          <q-item-section>-->
        <!--            Queue-->
        <!--          </q-item-section>-->
        <!--        </q-item>-->

        <!--<q-expansion-item
        :header-inset-level="0.5"
        dense-toggle
        switch-toggle-side
        expand-separator
        label="Misc"
      >
        <q-item
          class="--inset-level-2"
          to="/manage/misc/geolocation-log"
          exact
        >
          <q-item-section>
            Geolocation Log
          </q-item-section>
        </q-item>
      </q-expansion-item>-->
        <q-expansion-item
          :header-inset-level="0.5"
          dense-toggle
          switch-toggle-side
          expand-separator
          label="Products"
        >
          <q-item
            class="--inset-level-2"
            to="/inventory/products/vehicles"
            exact
          >
            <q-item-section>
              Vehicles
            </q-item-section>
          </q-item>
          <q-item class="--inset-level-2" to="/inventory/products/jobs" exact>
            <q-item-section>
              Jobs
            </q-item-section>
          </q-item>
        </q-expansion-item>
      </q-expansion-item>
    </q-list>
  </q-drawer>
</template>

<script>
export default {
  name: "TheLeftDrawer",
  computed: {
    leftDrawerOpen() {
      return this.$store.state.leftDrawerOpen;
    },
    drewRestrictedAccess() {
      return this.$store.getters["user/drewRestrictedAccess"];
    }
  }
};
</script>

<style lang="scss">
.app-sidebar-drawer__content {
  .app-menu {
    a.q-item.q-link {
      border-radius: 0 10px 10px 0;
      margin-right: $space-md;

      &.q-router-link--active {
        > .q-focus-helper {
          background: currentColor;
          opacity: 0.14;
        }
      }
    }

    .q-item__section--main {
      font-size: 1.05em;
    }

    .q-expansion-item--expanded {
      > div {
        > .q-item {
          > .q-item__section--main {
            color: color(primary);
            font-weight: 700;
          }
        }
      }
    }

    .q-item__section--avatar {
      color: color(primary);
    }
  }

  + .q-layout__shadow:after {
    box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%), 0 0px 10px rgb(0 0 0 / 14%) !important;
  }

  .--inset-level-1 {
    padding-left: 72px;
  }
  .--inset-level-2 {
    padding-left: 100px;
  }
}
</style>
